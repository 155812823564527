import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import classNames from "classnames";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown, Form, Modal } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { connect, useDispatch, useSelector } from "react-redux";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import AutoComplete from "../../../Components/AutoComplete/AutoComplete";
import { sortLocation } from "../../../Helper/helper";
import {
  UpdateOrderAction,
  getFreeAgentData,
  getLocationByMenuDataAction,
  getOneOrderDataAction,
  getOrderDataAction,
  getOrderFilterAction,
  getswapagantAction,
  locationSearch,
} from "../../../Store/Action/CreateAgents/UserAction";
import {
  RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR,
  RESET_UPDATE_SUPER_ADMIN_ORDER,
  Us_Pattern_Number,
  contactValidation,
  countryCodeValidation,
  formatPhoneNumber,
} from "../../../Store/type/Constant";
import Additem from "./Additem";

const EditOrder = (props) => {
  const dispatch = useDispatch();
  const [fullOrderDetails, setFullOrderDetails] = useState(null);
  const [checkCheckboxValue, setcheckCheckboxValue] = useState(0);
  const [isContactAvailable, setIsContactAvailable] = useState(false);
  const [addressOption, setAddressOption] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isAddItem, setIsAddItem] = useState(false);
  const [addedItem, setAddedItem] = useState([]);
  const [totlaPizzaItem, setTotalPizzaItem] = useState(0);
  const [freePizza, setFreePizza] = useState(null);
  const [chepestPizzaItem, setChepestPizzaItem] = useState(null);
  const [ChangeAmount, setChangeAmount] = useState(0);
  const [btnLoading, setbtnLoading] = useState(false);
  const [addInput, setaddInput] = useState([{ name: "", amount: "" }]);
  const notifyError = (msg) => toast.error(msg);
  const [locationSalestax, setlocationSalestax] = useState(0);
  const [locationservicefee, setlocationservicefee] = useState(0);
  const [currentAgent, setCurrentAgent] = useState({});
  const [swapShow, setswapShow] = useState(false);
  const handleswapClose = () => setswapShow(false);
  const handleswapShow = () => setswapShow(true);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [same, setSame] = useState(false);
  const initialState = {
    customer_name: "",
    address: "",
    countryCode: "+91",
    state: "",
    city: "",
    pincode: "",
    delivery_agent: "",
    delivery_agent_name: "",
    delivery_time: moment().toDate(),
    delivery_leave_time: moment().subtract(1, "minute").toDate(),
    delivery_date: null,
    email: "",
    dob: null,
    location: "",
    location_id: "",
    note: "",
    email: "",
    contact_search: "",
    type: "",
    customer_id: "",
    client_name: "",
    address1: "",
    address2: "",
  };
  const [state, setState] = useState(initialState);
  const [error, setError] = useState({
    customer_name: false,
    address: false,
    contactNo: false,
    countryCode: false,
    state: false,
    city: false,
    pincode: false,
    delivery_time: false,
    delivery_leave_time: false,
    delivery_date: false,
    delivery_fees: false,
    manual_discount: false,
    total_tipamount: false,
    delivery_agent: false,
    prm_amount: false,
    email: false,
    dob: false,
    location: false,
    note: false,
    name: false,
    amount: false,
    contact_search: false,
    client_name: false,
    address1: false,
    address2: false,
  });

  const [config, setConfig] = useState({
    sales_tax: false,
    service_fees: false,
    delivery_fees: false,
    manual_discount: false,
    discount: false,
    tip: false,
  });

  const [tax, setTax] = useState({
    sales_tax: 0,
    service_fees: 0,
    delivery_fees: 0,
    manual_discount: 0,
    discount: 0,
    tip: 0,
    prm_amount: 0,
    total_tipamount: 0,
  });

  const [totalAmount, setTotalAmount] = useState({
    total: 0,
    subTotal: 0,
    grandTotal: 0,
  });
  const [addInputErrors, setaddInputErrors] = useState([
    { name: "", amount: "" },
  ]);

  const [searchLocation, setSearchLocation] = useState("");
  const [resultLocation, setResultLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [geometry, setGeometry] = useState({
    lat: "",
    lng: "",
  });

  const [swapAgents, setSwapAgents] = useState([]);
  const [swapedId, setSwapedId] = useState(""); //////////////////////////////////////////////////////////////////////////////////////////

  const agents = useSelector((state) => state.getAgentsReducer.user.data);
  const LocationByAgents = useSelector(
    (state) => state.getLocationByAgents.getLocationByAgents.data
  );

  var res = state?.contact_search
    ?.replace(/\D+/g, "")
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1$2$3");

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   props.getOrderFilterAction();
  // }, []);

  useEffect(() => {
    if (!!state.location && state.location !== "") {
      props.getswapagantAction({
        locationID: state.location_id,
        agentID: currentAgent._id,
      });
    }
  }, [state.location]);

  useEffect(() => {
    if (props.editOrderReducer?.success) {
      toast.success("Order is updated successfully.");
      props.setOrderEdit(false);
      // props.getOrderFilterAction();
      // props.resetUpdateOrder();
    } else if (props.editOrderReducer?.error) {
      toast.error(props.editOrderReducer?.errors);
      // props.resetUpdateOrder();
    }
  }, [props.editOrderReducer]);

  useEffect(() => {
    if (props.getLocationByMenuReducer?.success) {
      const list =
        props.getLocationByMenuReducer.data[0]?.locationdata[0]?.salestax;
      setlocationSalestax(list);
      const dataservicefee =
        props.getLocationByMenuReducer.data[0]?.locationdata[0]?.servicefee ??
        18;
      setlocationservicefee(dataservicefee);
    }
  }, [props.getLocationByMenuReducer]);

  useEffect(() => {
    const filterData = addedItem?.filter(
      (e) => parseFloat(e.status) === 1 && e.is_pizza === 1
    );
    if (filterData?.length !== 0) {
      const cheapestItem = filterData?.reduce((a, b) => {
        return a.price < b.price ? a : b;
      });
      setChepestPizzaItem({ ...cheapestItem, quantity: 1 });
    } else {
      const cheapestItem = null;
      setChepestPizzaItem({ ...cheapestItem, quantity: 1 });
    }
  }, [addedItem]);

  // useEffect(() => {
  //   if (totlaPizzaItem >= 6) {
  //     const freePizza = { ...chepestPizzaItem };
  //     setFreePizza(freePizza);
  //   } else {
  //     setFreePizza(null);
  //   }
  // }, [totlaPizzaItem]);

  useEffect(() => {
    if (addedItem?.length === 0) {
      setTotalAmount((prevState) => ({
        ...prevState,
        total: 0,
        subTotal: 0,
        grandTotal: 0,
        ChangeAmount: 0,
      }));
    } else {
      const totalPizzaQuentity = addedItem
        ?.filter((e) => e.is_pizza == 1)
        ?.map((e) => e.quantity);
      setTotalPizzaItem(totalPizzaQuentity?.reduce((a, b) => a + b, 0));

      const totalAmount = addedItem
        ?.filter((e) => e.quantity > 0)
        ?.map((e) => {
          return e.price * e.quantity;
        })
        ?.reduce((a, b) => a + b, 0);

      const menual_Discount = tax.manual_discount
        ? parseFloat(tax?.manual_discount)?.toFixed(2)
        : parseFloat(0)?.toFixed(2);

      const _sales_tax = config.sales_tax
        ? (
            ((parseFloat(totalAmount) - parseFloat(menual_Discount)) / 100) *
            tax.sales_tax
          ).toFixed(2)
        : 0;

      const totalAfterDiscount =
        parseFloat(totalAmount) -
        parseFloat(config.manual_discount ? menual_Discount : 0);

      const _service_fees = config.service_fees
        ? (
            (parseFloat(
              parseFloat(totalAfterDiscount) + parseFloat(_sales_tax)
            ) /
              100) *
            locationservicefee
          ).toFixed(2)
        : 0;
      setTotalAmount((prevState) => ({
        ...prevState,
        total: parseFloat(totalAmount),
        subTotal: totalAfterDiscount + parseFloat(_sales_tax),
        grandTotal:
          totalAfterDiscount +
          parseFloat(_sales_tax) +
          parseFloat(_service_fees) +
          parseFloat(config?.delivery_fees ? tax.delivery_fees : 0) +
          parseFloat(config?.tip ? tax?.total_tipamount : 0),
      }));
    }
  }, [addedItem, tax, config, locationservicefee]);

  useEffect(() => {
    if (!!locationSalestax) {
      const totalPizzaQuentity = addedItem
        ?.filter((e) => e.is_pizza == 1)
        ?.map((e) => e.quantity);
      setTotalPizzaItem(totalPizzaQuentity?.reduce((a, b) => a + b, 0));
      const totalAmount = addedItem
        ?.filter((e) => parseFloat(e.isfree) !== 1)
        ?.map((e) => {
          return e.price * e.quantity;
        })
        ?.reduce((a, b) => a + b, 0);
    }
  }, [addedItem, locationSalestax]);
  const getOneOrderandSet = async () => {
    const data = await getOneOrderDataAction(props.SelectedEditID);
    setFullOrderDetails(data?.data);
  };
  useEffect(() => {
    if (!!props.SelectedEditID && fullOrderDetails) {
      // const data = props.orderfilterSuperadmin?.data?.find(
      //   (e, i) => e._id === props.SelectedEditID
      // );
      const data = fullOrderDetails;

      setConfig({
        sales_tax: data?.applysalestax === 1 ? true : false,
        service_fees: data?.servicefees === 1 ? true : false,
        delivery_fees: data?.deliveryvalue !== 0 ? true : false,
        manual_discount: data?.manual_discount !== 0 ? true : false,
        discount: data?.discount !== 0 ? true : false,
        tip: data?.tipamount === 1 ? true : false,
      });
    }
  }, [props.SelectedEditID, fullOrderDetails]);
  useEffect(() => {
    if (!!props.SelectedEditID) {
      getOneOrderandSet();
    }
  }, [props?.SelectedEditID]);
  useEffect(() => {
    if (props.orderfilterSuperadmin.success && fullOrderDetails) {
      // const data = props.orderfilterSuperadmin?.data?.find(
      //   (e, i) => e._id === props.SelectedEditID
      // );
      const data = fullOrderDetails;
      setCurrentAgent(data?.agents[0]);
      setAddressOption(data?.address_id);
      setState({
        customer_name: data?.customers[0]?.name,
        address: data?.address[0]?.address,
        address_id: data?.address_id,
        state: data?.address[0]?.state,
        city: data?.address[0]?.city,
        pincode: data?.address[0]?.pincode,
        delivery_agent: data?.agents[0]?._id,
        delivery_agent_name: data?.agents[0]?.name,
        delivery_time: moment(data?.deliverytimestring, "hh:mm a").toDate(),
        delivery_leave_time: moment(data?.leavetimestring, "hh:mm a").toDate(),
        // delivery_date: moment(data?.date).toDate(),
        // delivery_date: new Date(data?.date),
        delivery_date: moment(data?.deliveryDateUnform, "MM-DD-yyyy").toDate(),
        dob: data?.customers[0]?.dob
          ? moment(data?.customers[0]?.dob, "MM-DD-yyyy").toDate()
          : "",
        location: data?.locationdata[0]?.location_name,
        note: data?.customers[0]?.note,
        email: data?.customers[0]?.email,
        contact_search: formatPhoneNumber(data?.customers[0]?.number),
        location_id: data?.locationdata[0]?._id,
        customer_id: data?.customer_id,
        type: "",
        client_name: data?.address[0]?.name,
        address1: data?.address[0]?.address1,
        address2: data?.address[0]?.address2,
      });
      const tipList = data?.Tipamounts?.map((e) => {
        return {
          name: e?.name,
          amount: e?.amount,
        };
      });
      const ErrortipList = data?.Tipamounts?.map((e) => {
        return {
          name: false,
          amount: false,
        };
      });
      if (data?.Tipamounts?.length !== 0) {
        setaddInput(tipList);
        setaddInputErrors(ErrortipList);
      }
      setTax({
        sales_tax: locationSalestax,
        service_fees: locationservicefee ?? data?.servicevalue,
        delivery_fees: data?.deliveryvalue,
        manual_discount: data?.manual_discount,
        discount: data?.discount,
        tip: data?.tipamount,
        prm_amount: 0,
        total_tipamount: data?.totaltipamount,
      });
      setTotalAmount({
        total: data?.amount,
        subTotal: data?.subtotal,
        grandTotal: data?.grandtotal,
      });
      const list = data?.menu
        ?.filter((e) => {
          return e?.isfree !== 1;
        })
        .map((e) => {
          return {
            ...e,
            image: e?.itemmenu[0]?.image,
            is_pizza: e?.itemmenu[0]?.is_pizza,
            item_name: e?.itemmenu[0]?.item_name,
            location: e?.itemmenu[0]?.location,
            price: e?.itemmenu[0]?.price,
            menu_item_id: e?.menu_item_id,
          };
        });
      setAddedItem(list);
      setSelectedOrder(list);
    }
  }, [
    props.orderfilterSuperadmin,
    locationSalestax,
    fullOrderDetails,
    locationservicefee,
  ]);

  useEffect(() => {
    if (state.delivery_leave_time >= state.delivery_time) {
      setError((prevState) => ({
        ...prevState,
        delivery_leave_time: "Leave time not greater than delivery time",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        delivery_leave_time: false,
      }));
    }
  }, [state.delivery_leave_time, state.delivery_time]);

  useEffect(() => {
    if (
      state.delivery_date !== "" &&
      state.delivery_time !== "" &&
      state.delivery_leave_time !== "" &&
      state.location !== ""
    ) {
      dispatch(
        getFreeAgentData({
          details: {
            id: state.location_id,
            leavetime: moment(state.delivery_leave_time).format("h:mm A"),
            deliverytime: moment(state.delivery_time).format("h:mm A"),
            leavetimestring: moment(state.delivery_leave_time).format("h:mm A"),
            deliverytimestring: moment(state.delivery_time).format("h:mm A"),
            date: moment(state.delivery_date).format("MM-DD-yyyy"),
            // id: locationID,
          },
        })
      );
    }
  }, [
    state.delivery_date,
    state.delivery_leave_time,
    state.delivery_time,
    state.location,
  ]);

  useEffect(() => {
    if (!!state.location && state.location !== "") {
      dispatch(getLocationByMenuDataAction(state.location_id));
    }
  }, [state.location]);

  useEffect(() => {
    if (props.getswapagentReducer.success) {
      const data = props.getswapagentReducer.data;
      setSwapAgents(data);
    }
  }, [props.getswapagentReducer]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleConfigInput = (e, index) => {
    const { name, value } = e.target;
    if (name === "delivery_fees") {
      setTax((preve) => ({
        ...preve,
        delivery_fees: value === "" ? 0 : Number(value).toString(),
      }));
      if (value <= 0) {
        setError((preve) => ({
          ...preve,
          delivery_fees: "Enter Delivery Fees",
        }));
      } else if (value > 0) {
        setError((preve) => ({
          ...preve,
          delivery_fees: false,
        }));
      }
    } else if (name === "manual_discount") {
      setTax((preve) => ({
        ...preve,
        manual_discount:
          freePizza === null && parseFloat(value) === ""
            ? 0
            : Number(value).toString(),
      }));

      if (value <= 0) {
        setError((preve) => ({
          ...preve,
          manual_discount: "Enter manual_discount",
        }));
      } else if (value > totalAmount.total) {
        setError((preve) => ({
          ...preve,
          manual_discount: "Enter valid discount",
        }));
      } else if (value > 0) {
        setError((preve) => ({
          ...preve,
          manual_discount: false,
        }));
      }
    } else if (name === "discount") {
      setTax((preve) => ({
        ...preve,
        discount:
          freePizza !== null && parseFloat(value) === ""
            ? 0
            : Number(value).toString(),
      }));

      if (value <= 0) {
        setError((preve) => ({
          ...preve,
          discount: "Enter discount",
        }));
      } else if (value > totalAmount.total) {
        setError((preve) => ({
          ...preve,
          discount: "Enter valid discount",
        }));
      } else if (value > 0) {
        setError((preve) => ({
          ...preve,
          discount: false,
        }));
      }
    } else if (name === "total_tipamount") {
      const _value = value.startsWith("0") ? value.slice(1) : value;
      if (!isNaN(value)) {
        setTax((preve) => ({
          ...preve,
          total_tipamount: _value === "" ? 0 : _value,
        }));
      } else return;
      if (_value <= 0) {
        setError((preve) => ({
          ...preve,
          total_tipamount: "Enter total_tipamount",
        }));
      } else if (_value > 0) {
        setError((preve) => ({
          ...preve,
          total_tipamount: false,
        }));
      }
    } else {
      setTax((preve) => ({
        ...preve,
        [name]: value,
      }));
    }

    if (name === "name" || name === "amount") {
      const list = [...addInput];
      list[index][e.target.name] = e.target.value;
      setaddInput(list);
    }
    if (name === "name" || name === "amount") {
      const Errorlist = [...addInputErrors];
      Errorlist[index][e.target.name] = e.target.value;
      setaddInputErrors(Errorlist);

      const amountArray = addInput?.map((v, i) => {
        return v?.amount === "" ? 0 : v?.amount;
      });
      const amountTotal = amountArray?.reduce((a, b) => {
        return parseFloat(a) + parseFloat(b);
      });
      setChangeAmount(amountTotal);
    }

    // if (onlyNumberValidation.test(value)) {
    //   setTax((prevState) => ({
    //     ...prevState,
    //     [name]: value === "" ? 0 : Number(value).toString(),
    //   }));
    // } else return;

    if (name === "prm_amount" && parseFloat(value) > parseFloat(tax.tip)) {
      setError((prevState) => ({
        ...prevState,
        prm_amount: "Prm amount not greter than tip amount",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        prm_amount: false,
      }));
    }
  };

  const addInputValue = () => {
    const addtipvalidation = addInput?.map((e, i) => {
      if (e.name !== "" && e.amount !== "") {
        return true;
      } else return false;
    });

    const x = addtipvalidation?.every((e, i) => {
      return e;
    });
    if (x) {
      setaddInput([...addInput, { name: "", amount: "" }]);
    }
    addInputValueErrors();
  };

  const addInputValueErrors = () => {
    const addtipvalidation = addInputErrors?.map((e, i) => {
      if (e.name !== "" && e.amount !== "") {
        return true;
      } else return false;
    });

    const x = addtipvalidation?.every((e, i) => {
      return e;
    });
    if (x) {
      setaddInputErrors([...addInputErrors, { name: "", amount: "" }]);
    }
  };

  const removeInputeValue = (index, amt) => {
    const list = [...addInput];
    list.splice(index, 1);
    setaddInput(list);
    addInput?.length !== 1 && setChangeAmount(ChangeAmount - amt);

    for (let i = 0; i < addInput.length; i++) {
      if (addInput?.length === 1) {
        const list = [...addInput];
        list.splice(index, 0);
        setaddInput(list);
      }
    }
    removeInputeValueErrors();
  };

  const removeInputeValueErrors = (index) => {
    const list = [...addInputErrors];
    list.splice(index, 1);
    setaddInputErrors(list);

    for (let i = 0; i < addInputErrors.length; i++) {
      if (addInputErrors?.length === 1) {
        const list = [...addInputErrors];
        list.splice(index, 0);
        setaddInputErrors(list);
      }
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "countryCode") {
      if (value.length <= 4) {
        setState((prevState) => ({
          ...prevState,
          countryCode: value === "+" ? parseFloat("0") : parseFloat(value),
        }));
      } else return;
    } else if (name === "contactNo") {
      if (value.length <= 10) {
        setState((prevState) => ({
          ...prevState,
          contactNo: value,
        }));
      } else return;
    } else if (name === "email") {
      setState((prevState) => ({
        ...prevState,
        email: value,
      }));
    } else if (name === "pincode") {
      if (value.length <= 5) {
        setState((prevState) => ({
          ...prevState,
          pincode: value,
        }));
      } else return;
    } else if (name === "note") {
      if (value.length <= 256) {
        setState((prevState) => ({
          ...prevState,
          note: value,
        }));
      }
    } else if (name === "delivery_agent") {
      if (value == "swapOption") {
        handleswapShow();
      } else {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    // setState((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
    switch (name) {
      case "customer_name":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            customer_name: "Enter Coustomer Name",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            customer_name: false,
          }));
        }
        break;

      case "contact_search":
        if (e.target.value.length == 0) {
          setError((prevState) => ({
            ...prevState,
            contact_search: "number can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setError((prevState) => ({
            ...prevState,
            contact_search: "Enter valid number",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            contact_search: false,
          }));
        }
        break;

      case "location":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            location: "Enter Coustomer Name",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            location: false,
          }));
        }
        break;

      case "note":
        if (value.length >= 256) {
          setError((prevState) => ({
            ...prevState,
            note: "Enter Only 256 Characters",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            note: false,
          }));
        }
        break;

      case "contactNo":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            contactNo: "Enter Agent contact no.",
          }));
        } else if (!contactValidation.test(value)) {
          setError((prevState) => ({
            ...prevState,
            contactNo: "Enter Valid contact no.",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            contactNo: false,
          }));
        }
        break;
      // case "email":
      //   if (value === "") {
      //     setError((prevState) => ({
      //       ...prevState,
      //       email: "Enter Customer email",
      //     }));
      //   } else if (!validEmailRegex.test(value)) {
      //     setError((prevState) => ({
      //       ...prevState,
      //       email: "Enter Valid email",
      //     }));
      //   } else {
      //     setError((prevState) => ({
      //       ...prevState,
      //       email: false,
      //     }));
      //   }
      //   break;

      case "countryCode":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            countryCode: "Enter Country code",
          }));
        } else if (!countryCodeValidation.test(value)) {
          setError((prevState) => ({
            ...prevState,
            countryCode: "Enter country code",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            countryCode: false,
          }));
        }
        break;

      case "address":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            address: "Enter Address",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            address: false,
          }));
        }
        break;
      case "client_name":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            client_name: "Enter client_name",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            client_name: false,
          }));
        }
        break;
      case "address1":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            address1: "Enter address1",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            address1: false,
          }));
        }
        break;
      // case "address2":
      //   if (value === "") {
      //     setError((prevState) => ({
      //       ...prevState,
      //       address2: "Enter address2",
      //     }));
      //   } else {
      //     setError((prevState) => ({
      //       ...prevState,
      //       address2: false,
      //     }));
      //   }
      //   break;

      case "state":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            state: "Enter State",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            state: false,
          }));
        }
        break;

      case "city":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            city: "Enter City",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            city: false,
          }));
        }
        break;

      case "pincode":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            pincode: "Enter Pincode",
          }));
        } else if (value.length < 5) {
          setError((prevState) => ({
            ...prevState,
            pincode: "Enter Valid Pincode",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            pincode: false,
          }));
        }
        break;
      case "delivery_agent":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            delivery_agent: "Select Agent",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            delivery_agent: false,
          }));
        }
        break;
      case "delivery_date":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            delivery_date: "Select delivery_date",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            delivery_date: false,
          }));
        }
        break;

      case "delivery_time":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            delivery_time: "Enter Valid Time",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            delivery_time: false,
          }));
        }
        break;

      case "delivery_leave_time":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            delivery_leave_time: "Enter Valid Time",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            delivery_leave_time: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleAddOption = (e) => {
    const { name, value } = e.target;
    const address = selectedCustomer.address.find((e) => e._id === value);
    if (value !== "" && value !== "add") {
      setState((prevState) => ({
        ...prevState,
        address: address.address,
        city: address.city,
        pincode: address.pincode,
        state: address.state,
        client_name: address.client_name,
        address1: address.address1,
        address2: address.address2,
      }));
      setGeometry((pre) => ({
        ...pre,
        lat: address.latitude,
        lng: address.longitude,
      }));
      setSearchLocation(address.address1);
    } else if (value === "add") {
      setState((prevState) => ({
        ...prevState,
        address: "",
        city: "",
        pincode: "",
        state: "",
        client_name: "",
        address1: "",
        address2: "",
      }));
      setGeometry((pre) => ({
        ...pre,
        lat: "",
        lng: "",
      }));
      setSearchLocation("");
    }
    setAddressOption(value);
    if (value === "") {
      setError((prevState) => ({
        ...prevState,
        address: "Select Address Option",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        address: false,
      }));
    }
    if (value === "") {
      setError((prevState) => ({
        ...prevState,
        pincode: "Select pincode Option",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        pincode: false,
      }));
    }
    if (value === "") {
      setError((prevState) => ({
        ...prevState,
        client_name: "Select client_name Option",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        client_name: false,
      }));
    }
    if (value === "") {
      setError((prevState) => ({
        ...prevState,
        address1: "Select address1 Option",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        address1: false,
      }));
    }
    // if (value === "") {
    //   setError((prevState) => ({
    //     ...prevState,
    //     address2: "Select address2 Option",
    //   }));
    // } else {
    //   setError((prevState) => ({
    //     ...prevState,
    //     address2: false,
    //   }));
    // }
    if (value === "") {
      setError((prevState) => ({
        ...prevState,
        city: "Select city Option",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        city: false,
      }));
    }
    if (value === "") {
      setError((prevState) => ({
        ...prevState,
        state: "Select state Option",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        state: false,
      }));
    }
  };

  const handleCheckbox = (e) => {
    const { name, checked, value } = e.target;
    if (checked) {
      setcheckCheckboxValue(value);
    } else {
      setcheckCheckboxValue(0);
    }
  };

  useEffect(() => {
    if (props.orderfilterSuperadmin?.success && fullOrderDetails) {
      // const data = props.orderfilterSuperadmin.data.find(
      //   (e, i) => e._id === props.SelectedEditID
      // );
      const data = fullOrderDetails;
      if (data?.ezCater == 1) {
        setcheckCheckboxValue(1);
      } else if (data?.ezCater == 2) {
        setcheckCheckboxValue(2);
      } else {
        setcheckCheckboxValue(0);
      }
    }
  }, [props.orderfilterSuperadmin, fullOrderDetails]);

  const handleRemoveItem = (id) => {
    const list = addedItem.map((e) => {
      if (e._id === id && e.quantity > 0) {
        return {
          ...e,
          quantity: e.quantity - 1 < 0 ? 0 : e.quantity - 1,
        };
      } else return e;
    });
    // const _list = list?.filter((e) => parseFloat(e.quantity) !== 0);
    setAddedItem([...list]);
  };

  const handleAddItem = (id) => {
    const list = addedItem.map((e) => {
      if (e._id === id) {
        return {
          ...e,
          quantity: e.quantity + 1,
        };
      } else return e;
    });
    setAddedItem([...list]);
  };

  const handlecount = (id, value) => {
    const list = addedItem.map((e) => {
      if (e._id === id) {
        return {
          ...e,
          quantity: value !== "" ? parseInt(value) : 0,
        };
      } else return e;
    });
    setAddedItem([...list]);
  };

  const imgDefault = (e) => {
    e.target.src = "/images/menu-placeholder.jpg";
    e.target.onerror = null;
  };

  const handleCreateOrder = (e, i) => {
    if (config.delivery_fees && tax.delivery_fees == 0) {
      setError((prevState) => ({
        ...prevState,
        delivery_fees: "Enter State",
      }));
    }
    if (config.manual_discount && tax.manual_discount == 0) {
      setError((prevState) => ({
        ...prevState,
        manual_discount: "Enter manual_discount",
      }));
    }
    if (config.discount && tax.discount == 0) {
      setError((prevState) => ({
        ...prevState,
        discount: "Enter discount",
      }));
    }
    if (config.total_tipamount && tax.total_tipamount == 0) {
      setError((prevState) => ({
        ...prevState,
        total_tipamount: "Enter total_tipamount",
      }));
    }

    if (tax.manual_discount >= totalAmount.total) {
      setError((prevState) => ({
        ...prevState,
        manual_discount: "Enter valid discount",
      }));
    }
    if (tax.discount >= totalAmount.total) {
      setError((prevState) => ({
        ...prevState,
        discount: "Enter valid discount",
      }));
    }

    const addtipvalidation = addInput?.map((e, i) => {
      if (e.name !== "" && e.amount !== "") {
        return true;
      } else return false;
    });

    const x = addtipvalidation?.every((e, i) => {
      return e;
    });
    if (x) {
      setaddInputErrors([...addInputErrors, { name: "", amount: "" }]);
    }

    // e.preventDefault();
    if (state.location === "") {
      setError((prevState) => ({
        ...prevState,
        location: "Select location",
      }));
    }
    if (state.customer_name === "") {
      setError((prevState) => ({
        ...prevState,
        customer_name: "Enter Coustomer Name",
      }));
    }
    if (state.address === "") {
      setError((prevState) => ({
        ...prevState,
        address: "Enter Address",
      }));
    }
    if (state.delivery_agent === "") {
      setError((prevState) => ({
        ...prevState,
        delivery_agent: "Select Agent",
      }));
    }
    if (state.contactNo === "") {
      setError((prevState) => ({
        ...prevState,
        contactNo: "Enter Agent contact no.",
      }));
    }
    // if (state.email === "") {
    //   setError((prevState) => ({
    //     ...prevState,
    //     email: "Enter Customer Email",
    //   }));
    // }
    // if (state.dob === "") {
    //   setError((prevState) => ({
    //     ...prevState,
    //     dob: "Enter Customer dob",
    //   }));
    // }
    if (state.countryCode === "") {
      setError((prevState) => ({
        ...prevState,
        countryCode: "Enter Country code",
      }));
    }
    if (state.state === "") {
      setError((prevState) => ({
        ...prevState,
        state: "Enter State",
      }));
    }
    if (state.city === "") {
      setError((prevState) => ({
        ...prevState,
        city: "Enter City",
      }));
    }
    if (state.pincode === "") {
      setError((prevState) => ({
        ...prevState,
        pincode: "Enter Pincode",
      }));
    }
    if (state.client_name === "") {
      setError((prevState) => ({
        ...prevState,
        client_name: "Enter client_name",
      }));
    }
    if (state.address1 === "") {
      setError((prevState) => ({
        ...prevState,
        address1: "Enter address1",
      }));
    }
    // if (state.address2 === "") {
    //   setError((prevState) => ({
    //     ...prevState,
    //     address2: "Enter address2",
    //   }));
    // }
    if (state.contact_search === "") {
      setError((prevState) => ({
        ...prevState,
        contact_search: "contact_search can not be empty",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        contact_search: false,
      }));
    }
    if (state.delivery_date === "") {
      setError((prevState) => ({
        ...prevState,
        delivery_date: "Enter Delivery_Date",
      }));
    }

    if (
      config.delivery_fees &&
      (tax.delivery_fees == 0 || parseFloat(tax.delivery_fees) === 0)
    ) {
      setError((preve) => ({
        ...preve,
        delivery_fees: "Enter Delivery Fees",
      }));
    } else if (!config.delivery_fees) {
      setError((preve) => ({
        ...preve,
        delivery_fees: false,
      }));
    }
    if (
      config.manual_discount &&
      (tax.manual_discount == 0 || parseFloat(tax.manual_discount) === 0)
    ) {
      setError((preve) => ({
        ...preve,
        manual_discount: "Enter manual_discount",
      }));
    } else if (freePizza !== null) {
      setError((preve) => ({
        ...preve,
        manual_discount: false,
      }));
    } else if (!config.manual_discount) {
      setError((preve) => ({
        ...preve,
        manual_discount: false,
      }));
    }
    if (
      config.discount &&
      (tax.discount == 0 || parseFloat(tax.discount) === 0)
    ) {
      setError((preve) => ({
        ...preve,
        discount: "Enter discount",
      }));
    } else if (freePizza !== null) {
      setError((preve) => ({
        ...preve,
        discount: false,
      }));
    } else if (!config.discount) {
      setError((preve) => ({
        ...preve,
        discount: false,
      }));
    }
    if (
      config.tip &&
      (tax.total_tipamount == 0 || parseFloat(tax.total_tipamount) === 0)
    ) {
      setError((preve) => ({
        ...preve,
        total_tipamount: "Enter Delivery Fees",
      }));
    } else if (parseFloat(tax.total_tipamount < parseFloat(ChangeAmount))) {
      setError((preve) => ({
        ...preve,
        total_tipamount: "Enter Delivery Fees",
      }));
    }

    if (
      state.customer_name !== "" &&
      // state.address !== "" &&
      state.contact_search !== "" &&
      // state.countryCode !== "" &&
      state.delivery_agent !== "" &&
      state.state !== "" &&
      state.city !== "" &&
      state.client_name !== "" &&
      state.address1 !== "" &&
      // state.address2 !== "" &&
      // state.email !== "" &&
      // state.dob !== "" &&
      state.pincode !== "" &&
      state.location !== "" &&
      state.delivery_date !== "" &&
      parseFloat(tax.total_tipamount !== "" ? tax.total_tipamount : 0) >=
        parseFloat(ChangeAmount) &&
      // state.note !== "" &&
      // !error.customer_name &&
      !error.address &&
      !error.delivery_agent &&
      !error.delivery_date &&
      addedItem.length !== 0 &&
      // !error.countryCode &&
      // !error.contactNo &&
      !error.delivery_time &&
      !error.delivery_leave_time &&
      // !error.email &&
      // !error.dob &&
      !error.pincode &&
      !error.location &&
      !error.delivery_fees &&
      !error.manual_discount &&
      !error.discount &&
      !error.total_tipamount &&
      !error.contact_search &&
      totalAmount.total !== 0 &&
      ((config.tip && x) || !config.tip)
    ) {
      // const selectedItem = [...addedItem, { ...freePizza, isfree: "1" }];
      const selectedItem = !!freePizza
        ? [...addedItem, { ...freePizza, isfree: "1" }]
        : [...addedItem];
      const details = {
        name: state.client_name,
        address1: state.address1,
        address2: state.address2,
        customer_name: state.customer_name,
        customer_address: state.address,
        address_id:
          addressOption == "" || addressOption == "add" ? null : addressOption,
        agent_id: state.delivery_agent,
        email: state.email,
        number: res,
        countrycode: state.countryCode,
        leavetimestring: moment(state.delivery_leave_time).format("h:mm A"),
        deliverytimestring: moment(state.delivery_time).format("h:mm A"),
        leavetime: moment(state.delivery_leave_time).format("h:mm A"),
        deliverytime: moment(state.delivery_time).format("h:mm A"),
        date: moment(state.delivery_date).format("yyyy-MM-DD"),
        deliveryDateFrom: moment(state.delivery_date).format("MMMM Do, YYYY"),
        deliveryDateUnform: moment(state.delivery_date).format("MM-DD-yyyy"),
        dob: state.dob ? moment(state.dob).format("MM-DD-yyyy") : "",
        additem: selectedItem.map((e) => {
          return {
            ...e,
            menu_item_id: e.menu_item_id,
          };
        }),
        amount: parseFloat(totalAmount.total).toFixed(2),
        subtotal:
          freePizza !== null
            ? (totalAmount?.subTotal - freePizza?.price).toFixed(2)
            : (totalAmount?.subTotal).toFixed(2),
        applysalestax: config.sales_tax ? "1" : "0",
        saletaxamount: config.sales_tax
          ? (
              ((totalAmount.total -
                (config.manual_discount ? tax.manual_discount : 0)) /
                100) *
              tax.sales_tax
            ).toFixed(2)
          : parseFloat(0).toFixed(2),
        grandtotal:
          freePizza !== null
            ? (
                parseFloat(totalAmount?.grandTotal) -
                parseFloat(freePizza?.price)
              ).toFixed(2)
            : parseFloat(totalAmount?.grandTotal).toFixed(2),
        discount:
          freePizza !== null
            ? parseFloat(freePizza?.price).toFixed(2)
            : parseFloat(tax.discount).toFixed(2),
        deliveryfees: parseFloat(config.delivery_fees).toFixed(2) ? "1" : "0",
        deliveryvalue: config.delivery_fees
          ? parseFloat(tax.delivery_fees).toFixed(2)
          : parseFloat(0).toFixed(2),
        manual_discount: config?.manual_discount
          ? parseFloat(tax.manual_discount).toFixed(2)
          : parseFloat(0).toFixed(2),
        servicefees: config.service_fees ? "1" : "0",
        servicevalue: config.service_fees
          ? (
              parseFloat(
                parseFloat(totalAmount.subTotal) * locationservicefee
              ) / 100
            ).toFixed(2)
          : parseFloat(0).toFixed(2),
        tipamount: config.tip ? "1" : "0",
        tipvalue: parseFloat(tax.tip).toFixed(2),
        pincode: state.pincode,
        state: state.state,
        city: state.city,
        customer_id: state?.customer_id,
        prmamount: tax.prm_amount,
        location: selectedItem[0].location,
        type: "1",
        note: state.note,
        totaltipArray: addInput,
        totaltipamount:
          tax.total_tipamount == ""
            ? 0
            : parseFloat(tax.total_tipamount).toFixed(2),
        ezCater: checkCheckboxValue,
        tipAmountFlag: config.tip ? "1" : "0",
        // createdate: moment(new Date()).format("yyyy-MM-DD"),
        // createdDateUnform: moment(new Date()).format("MM-DD-yyyy"),
        latitude: state.latitude,
        longitude: state.longitude,
        ...(swapedId !== "" && { swap_order: swapedId }),
      };
      // console.log("details", details);
      // detail.details
      // console.log(details)
      props.UpdateOrderAction({
        data: details,
        id: props.SelectedEditID,
      });
      setbtnLoading(true);
    } else {
      let errorList = [];
      if (state.customer_name == "") {
        errorList = [...errorList, "customer name can't be empty."];
      }
      if (state.contact_search == "") {
        errorList = [...errorList, "contact search can not be empty"];
      } else if (!Us_Pattern_Number.test(state.contact_search)) {
        errorList = [...errorList, "Enter valid number"];
      }
      if (state.delivery_agent == "") {
        errorList = [...errorList, "Select agent"];
      }
      if (state.state == "") {
        errorList = [...errorList, "Enter state"];
      }
      if (state.city == "") {
        errorList = [...errorList, "Enter city"];
      }
      if (state.client_name == "") {
        errorList = [...errorList, "Enter client name"];
      }
      if (state.address1 == "") {
        errorList = [...errorList, "Enter address1"];
      }
      if (totalAmount.total === 0 || addedItem.length == 0) {
        errorList = [...errorList, "Please add atleast one item"];
      }
      if (state.pincode == "") {
        errorList = [...errorList, "Enter pincode"];
      } else if (state.pincode.length < 5) {
        errorList = [...errorList, "Enter Valid pincode "];
      }
      if (state.delivery_date == "") {
        errorList = [...errorList, "Enter delivery date"];
      }

      if (
        config.delivery_fees &&
        (tax.delivery_fees == 0 || parseFloat(tax.delivery_fees) === 0)
      ) {
        errorList = [...errorList, "Enter delivery fees"];
      }
      if (
        config.manual_discount &&
        (tax.manual_discount == 0 || parseFloat(tax.manual_discount) === 0)
      ) {
        errorList = [...errorList, "Enter manual discount"];
      } else if (freePizza !== null) {
        errorList = [...errorList, "Enter manual discount"];
      }
      if (!!config.tip && tax.total_tipamount == 0) {
        errorList = [...errorList, "Enter total tip amount"];
      } else if (parseFloat(tax.total_tipamount) < parseFloat(ChangeAmount)) {
        errorList = [
          ...errorList,
          "The tip distribution cannot be more than the total tip amount",
        ];
      }
      if (!!config.tip && !x) {
        errorList = [...errorList, "Enter tip amount"];
      }

      toast.error(() => {
        return errorList.length > 1 ? (
          <ul>
            {errorList.map((e, i) => {
              return <li key={i}>{e}</li>;
            })}
          </ul>
        ) : (
          <>
            {errorList.map((e, i) => {
              return <div key={i}>{e}</div>;
            })}
          </>
        );
      });
    }

    if (state.delivery_leave_time >= state.delivery_time) {
      notifyError("Leave time not grater than delivery time");
    } else if (parseFloat(tax.prm_amount) > parseFloat(tax.tip)) {
      notifyError("Prm amount not greter than tip amount");
    }
  };

  useEffect(() => {
    if (state.delivery_agent != "swapOption") {
      setSwapedId("");
    }
  }, [state.delivery_agent]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      type="button"
      ref={ref}
      className="form-select text-start"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  ));

  const CustomLocation = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            type="text"
            // name=""
            autoFocus
            autoComplete="off"
            className="mx-3 my-2 w-auto"
            placeholder="Search Location..."
            value={searchLocation}
            onChange={(e) => {
              setSearchLocation(e.target.value);
            }}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  useEffect(() => {
    const debounceFuc = debounce(() => {
      if (searchLocation !== "") {
        props.locationSearch(searchLocation);
      }
    }, 500);
    debounceFuc();
    return () => {
      debounceFuc.cancel();
    };
  }, [searchLocation]);

  useEffect(() => {
    if (props.searchLocationReducer.success) {
      const data = props.searchLocationReducer.data;
      setResultLocation(data.results);
    }
  }, [props.searchLocationReducer]);

  useEffect(() => {
    if (props.searchLocationReducer.success) {
      const data = props.searchLocationReducer.data;
      const sortData = sortLocation(data.results);
      setResultLocation(sortData);
    }
  }, [props.searchLocationReducer]);

  useEffect(() => {
    setSearchLocation("");
    setSelectedLocation(null);
  }, [addressOption]);

  useEffect(() => {
    if (searchLocation == "") {
      setResultLocation([]);
    }
  }, [searchLocation]);

  useEffect(() => {
    if (!!selectedLocation) {
      setGeometry({
        lat: selectedLocation.position.lat,
        lng: selectedLocation.position.lon,
      });
    } else {
      setGeometry({
        lat: "",
        lng: "",
      });
    }
  }, [selectedLocation]);

  const handleClickLocation = (data) => {
    const address = data.address.freeformAddress;
    const parts = address.split(","); // Split the address into an array using commas
    const reducedAddress = parts.slice(0, -2).join(","); // Remove the last two elements and join them back
    setSelectedLocation(data);
    setSearchLocation(reducedAddress);
    setState((pre) => ({
      ...pre,
      address1: reducedAddress,
      city: data.address.municipality,
      state: data.address.countrySubdivisionName,
      pincode: !!data.address.postalCode ? data.address.postalCode : "",
    }));
  };

  return (
    <div style={{ overflow: "auto" }}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Order</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          minHeight: "350px",
        }}
      >
        <div className="row">
          <div className="col-xl-3 col-lg-6 mb-3">
            <Form.Label>Location:</Form.Label>
            <div className="form-group event-drp">
              <select
                id="location"
                name="location"
                disabled
                value={state.location}
                className={classNames("form-control col-12", {
                  requireField: error.location,
                })}
              >
                <option value="" className="d-none">
                  {state.location}
                </option>
              </select>
            </div>
          </div>
          <>
            <div className="col-xl-4 col-lg-12 d-flex align-items-center">
              <div
                className="row mx-0 "
                style={{
                  width: "100%",
                }}
              >
                <div className="form-check col-6">
                  <Form.Label className="mt-1">ezCater</Form.Label>
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    value="1"
                    checked={checkCheckboxValue == 1}
                    id="flexCheckChecked"
                    onChange={handleCheckbox}
                  />
                </div>
                <div className="form-check col-6">
                  <Form.Label className="mt-1">OLO Catering</Form.Label>
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    value="2"
                    checked={checkCheckboxValue == 2}
                    id="flexCheckChecked"
                    onChange={handleCheckbox}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="row gx-3">
                <div className="col-6 ">
                  <Form.Group className="mb-3">
                    <Form.Label>Customer Name:</Form.Label>
                    <Form.Control
                      type="customer_name"
                      name="customer_name"
                      placeholder="Enter Customer name"
                      value={state.customer_name}
                      // disabled={isContactAvailable}
                      onChange={handleInput}
                      className={classNames({
                        requireField: error.customer_name,
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Contact No:</Form.Label>
                    <MaskedInput
                      mask={[
                        "(",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        ")",
                        " ",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                      ]}
                      // type="number"
                      name="contact_search"
                      placeholder="Enter Contact No."
                      guide={false}
                      autoComplete="new-password"
                      id="my-input-id number"
                      value={state?.contact_search}
                      onChange={handleInput}
                      onKeyUp={handleInput}
                      className={classNames("form-control mb-3", {
                        requireField: error.contact_search,
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address (Optional):</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter Email Address"
                      value={state.email}
                      // disabled={isContactAvailable}
                      onChange={handleInput}
                      className={classNames({
                        requireField: error.email,
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Label className="d-flex">
                    Date of Birth (Optional):
                  </Form.Label>
                  <ReactDatePicker
                    showYearDropdown
                    name="dob"
                    // disabled={isContactAvailable}
                    placeholderText="MM-DD-YYYY"
                    autoComplete="off"
                    dateFormat={"MM-dd-yyyy"}
                    selected={state.dob}
                    className={classNames("form-control", {
                      requireField: error.dob,
                    })}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setState((prevState) => ({
                        ...prevState,
                        dob: date,
                      }));
                      // if (date === null || date === "") {
                      //   setError((prevState) => ({
                      //     ...prevState,
                      //     dob: "Enter Valid Date of Birth",
                      //   }));
                      // } else {
                      //   setError((prevState) => ({
                      //     ...prevState,
                      //     dob: false,
                      //   }));
                      // }
                    }}
                  />
                </div>
                {!isContactAvailable ? (
                  <div className="col col-12">
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Business Name:
                        </Form.Label>
                        <Form.Control
                          onChange={handleInput}
                          type="text"
                          placeholder="Enter Business Name"
                          name="client_name"
                          label="client_name"
                          value={state.client_name}
                          id="client_name"
                          className={classNames({
                            requireField: error.client_name,
                          })}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Search Location:
                        </Form.Label>
                        <AutoComplete state={state} setState={setState} />
                        {/* <Dropdown>
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                          >
                            {searchLocation !== ""
                              ? searchLocation
                              : "Search location"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            as={CustomLocation}
                            style={{
                              maxHeight: "300px",
                              overflow: "auto",
                              width: "100%",
                            }}
                          >
                            {resultLocation.length !== 0 ? (
                              resultLocation.map((e, i) => {
                                return (
                                  <Dropdown.Item
                                    key={i}
                                    onClick={() => {
                                      handleClickLocation(e);
                                    }}
                                    className="searchLocationClass"
                                  >
                                    <div className="d-flex">
                                      <div className="me-2">
                                        <i
                                          className="ri-map-pin-fill"
                                          style={{
                                            color: "#C3262D",
                                            fontSize: "25px",
                                          }}
                                        ></i>
                                      </div>
                                      <div
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <strong>
                                          {e.type == "Geography"
                                            ? `${
                                                !!e.address.streetNumber
                                                  ? e.address.streetNumber +
                                                    ", "
                                                  : ""
                                              }${e.address.streetNumber}`
                                            : e.address.freeformAddress}
                                        </strong>
                                        <div
                                          className="text-gray"
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {e.type == "Geography"
                                            ? `${e.address.municipality}, ${e.address.countrySubdivisionName}, ${e.address.country}`
                                            : `${e.address.streetName}, ${e.address.municipality}, ${e.address.countrySubdivisionName}, ${e.address.country}`}
                                        </div>
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                );
                              })
                            ) : (
                              <div className="text-center my-4 text-gray">
                                {" "}
                                {searchLocation !== ""
                                  ? "No Location Found"
                                  : "Search Location"}{" "}
                              </div>
                            )}
                          </Dropdown.Menu>
                        </Dropdown> */}
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Address Line 1:</Form.Label>
                        <Form.Control
                          name="address1"
                          // as={"textarea"}
                          placeholder="Enter Address line 1"
                          value={state.address1}
                          // disabled={
                          //   addressOption !== "" &&
                          //   addressOption !== "add"
                          // }
                          onChange={handleInput}
                          className={classNames({
                            requireField: error.address1,
                          })}
                        />
                      </Form.Group>

                      <Form.Group className="mb-2">
                        <Form.Label>Address Line 2 (Optional):</Form.Label>
                        <Form.Control
                          name="address2"
                          // as={"textarea"}
                          placeholder="Enter Address line 2"
                          value={state.address2}
                          // disabled={
                          //   addressOption !== "" &&
                          //   addressOption !== "add"
                          // }
                          onChange={handleInput}
                          className={classNames({
                            requireField: error.address2,
                          })}
                        />
                      </Form.Group>
                    </>
                  </div>
                ) : (
                  <div className="col col-12">
                    <Form.Select
                      value={addressOption}
                      onChange={handleAddOption}
                      className={classNames("mb-3 col-6", {
                        requireField: error.address,
                      })}
                    >
                      <option value={""} disabled>
                        Select Address
                      </option>

                      <option value={"add"}>Add new address</option>
                      {!!selectedCustomer &&
                        selectedCustomer?.address.map((address, index) => {
                          return (
                            <option
                              value={address._id}
                              key={index}
                              className="my-3"
                            >
                              {address.address1 + " " + address.address2}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </div>
                )}{" "}
                {/*  pin state, city */}
                <Form.Group className="mb-3">
                  {(!isContactAvailable || addressOption !== "") && (
                    <div className="row gx-3">
                      <div className="">
                        {isContactAvailable && (
                          <>
                            <Form.Group className="mb-3">
                              <Form.Label style={{ fontWeight: "bold" }}>
                                Business Name:
                              </Form.Label>
                              <Form.Control
                                onChange={handleInput}
                                type="text"
                                placeholder="Enter Business Name"
                                name="client_name"
                                label="client_name"
                                value={state.client_name}
                                id="client_name"
                                className={classNames({
                                  requireField: error.address,
                                })}
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label style={{ fontWeight: "bold" }}>
                                Search Location:
                              </Form.Label>
                              <Dropdown>
                                <Dropdown.Toggle
                                  as={CustomToggle}
                                  id="dropdown-custom-components"
                                >
                                  {searchLocation !== ""
                                    ? searchLocation
                                    : "Search location"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  as={CustomLocation}
                                  style={{
                                    maxHeight: "300px",
                                    overflow: "auto",
                                    width: "100%",
                                  }}
                                >
                                  {resultLocation.length !== 0 ? (
                                    resultLocation.map((e, i) => {
                                      return (
                                        <Dropdown.Item
                                          key={i}
                                          onClick={() => {
                                            handleClickLocation(e);
                                          }}
                                          className="searchLocationClass"
                                        >
                                          <div className="d-flex">
                                            <div className="me-2">
                                              <i
                                                className="ri-map-pin-fill"
                                                style={{
                                                  color: "#C3262D",
                                                  fontSize: "25px",
                                                }}
                                              ></i>
                                            </div>
                                            <div
                                              style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              <strong>
                                                {e.type == "Geography"
                                                  ? `${
                                                      !!e.address.streetNumber
                                                        ? e.address
                                                            .streetNumber + ", "
                                                        : ""
                                                    }${e.address.streetNumber}`
                                                  : e.address.freeformAddress}
                                              </strong>
                                              <div
                                                className="text-gray"
                                                style={{
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {e.type == "Geography"
                                                  ? `${e.address.municipality}, ${e.address.countrySubdivisionName}, ${e.address.country}`
                                                  : `${e.address.streetName}, ${e.address.municipality}, ${e.address.countrySubdivisionName}, ${e.address.country}`}
                                              </div>
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                      );
                                    })
                                  ) : (
                                    <div className="text-center my-4 text-gray">
                                      {" "}
                                      {searchLocation !== ""
                                        ? "No Location Found"
                                        : "Search Location"}{" "}
                                    </div>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>Address Line 1:</Form.Label>
                              <Form.Control
                                name="address1"
                                // as={"textarea"}
                                placeholder="Enter Address line 1"
                                value={state.address1}
                                // disabled={
                                //   addressOption !== "" &&
                                //   addressOption !== "add"
                                // }
                                onChange={handleInput}
                                className={classNames({
                                  requireField: error.address1,
                                })}
                              />
                            </Form.Group>

                            <Form.Group className="mb-2">
                              <Form.Label>Address Line 2:</Form.Label>
                              <Form.Control
                                name="address2"
                                // as={"textarea"}
                                placeholder="Enter Address line 2"
                                value={state.address2}
                                // disabled={
                                //   addressOption !== "" &&
                                //   addressOption !== "add"
                                // }
                                onChange={handleInput}
                                className={classNames({
                                  requireField: error.address2,
                                })}
                              />
                            </Form.Group>
                          </>
                        )}
                      </div>

                      <div className="col-12 col-sm-4 mt-2">
                        <Form.Label>City:</Form.Label>
                        <Form.Control
                          name="city"
                          type="text"
                          placeholder="Enter City"
                          value={state.city}
                          // disabled={
                          //   addressOption !== "" && addressOption !== "add"
                          // }
                          onChange={handleInput}
                          className={classNames({
                            requireField: error.city,
                          })}
                        />
                      </div>
                      <div className="col-12 col-sm-4 mt-2">
                        <Form.Label>State:</Form.Label>
                        <Form.Control
                          name="state"
                          type="text"
                          placeholder="Enter State"
                          value={state.state}
                          // disabled={
                          //   addressOption !== "" && addressOption !== "add"
                          // }
                          onChange={handleInput}
                          className={classNames({
                            requireField: error.state,
                          })}
                        />
                      </div>
                      <div className="col-12 col-sm-4 mt-2">
                        <Form.Label>Zip Code:</Form.Label>
                        <Form.Control
                          name="pincode"
                          type="number"
                          placeholder="Enter Zip Code"
                          value={state.pincode}
                          maxLength={6}
                          // disabled={
                          //   addressOption !== "" && addressOption !== "add"
                          // }
                          onChange={handleInput}
                          className={classNames({
                            requireField: error.pincode,
                          })}
                        />
                      </div>
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 col-xl-4 col-md-6">
                  <Form.Label>Leave Time:</Form.Label>
                  {/* <Form.Control
                    name="delivery_leave_time"
                    type="time"
                    placeholder="Enter Delivery Leave Time"
                    value={state.delivery_leave_time}
                    onChange={handleInput}
                    className={classNames({
                      requireField: error.delivery_leave_time,
                    })}
                    style={{ height: "38px" }}
                  /> */}
                  <div
                    className={classNames("form-control col-md-6 mt-30", {
                      requireField: error.delivery_leave_time,
                    })}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* <TimePicker
                        autoOk
                        ampm={true}
                        value={state.delivery_leave_time}
                        placeholder="Suggested Leave Time*"
                        // format=""
                        clearable
                        onChange={(e) =>
                          handleInput({
                            target: {
                              value: e,
                              name: "delivery_leave_time",
                              id: "delivery_leave_time",
                            },
                          })
                        }
                      /> */}
                      <KeyboardTimePicker
                        autoOk
                        ampm={true}
                        value={state.delivery_leave_time}
                        placeholder="HH:MM A"
                        mask="__:__ _M"
                        style={{ color: "red" }}
                        onChange={(e) =>
                          handleInput({
                            target: {
                              value: e,
                              name: "delivery_leave_time",
                              id: "delivery_leave_time",
                            },
                          })
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div></div>
                </Form.Group>
                <Form.Group className="mb-3 col-xl-4 col-md-6">
                  <Form.Label>Delivery Time:</Form.Label>
                  {/* <Form.Control
                    name="delivery_time"
                    type="time"
                    placeholder="Enter Delivery Time"
                    value={state.delivery_time}
                    style={{ height: "38px" }}
                    onChange={handleInput}
                    className={classNames({
                      requireField: error.delivery_time,
                    })}
                  /> */}
                  <div
                    className={classNames("form-control col-md-6 mt-30", {
                      requireField: error.delivery_time,
                    })}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* <TimePicker
                        autoOk
                        ampm={true}
                        value={state.delivery_time}
                        placeholder="Requested Delivery Time*"
                        // format=""
                        clearable
                        onChange={(e) =>
                          handleInput({
                            target: {
                              value: e,
                              name: "delivery_time",
                              id: "delivery_time",
                            },
                          })
                        }
                      /> */}
                      <KeyboardTimePicker
                        autoOk
                        ampm={true}
                        value={state.delivery_time}
                        placeholder="HH:MM A"
                        mask="__:__ _M"
                        style={{ color: "red" }}
                        onChange={(e) =>
                          handleInput({
                            target: {
                              value: e,
                              name: "delivery_time",
                              id: "delivery_time",
                            },
                          })
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div></div>
                </Form.Group>
                <Form.Group className="mb-3 col-xl-4 col-md-6">
                  <Form.Label style={{ marginBottom: "-22px" }}>
                    Delivery Date:
                  </Form.Label>
                  <ReactDatePicker
                    showYearDropdown
                    name="delivery_date"
                    placeholderText="MM-DD-YYYY"
                    dateFormat={"MM-dd-yyyy"}
                    selected={state.delivery_date}
                    className={classNames("form-control mt-1", {
                      requireField: error.delivery_date,
                    })}
                    // minDate={new Date()}
                    onChange={(date) => {
                      setState((prevState) => ({
                        ...prevState,
                        delivery_date: date,
                      }));
                      if (date === null || date === "") {
                        setError((prevState) => ({
                          ...prevState,
                          delivery_date: "Enter Valid Date of Birth",
                        }));
                      } else {
                        setError((prevState) => ({
                          ...prevState,
                          delivery_date: false,
                        }));
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-12">
                  <Form.Label>Select Driver:</Form.Label>
                  <Form.Select
                    id="myselect"
                    name="delivery_agent"
                    value={state.delivery_agent}
                    onChange={handleInput}
                    className={classNames({
                      requireField: error.delivery_agent,
                    })}
                    onMouseUp={(e) => {
                      if (e.target.value == "swapOption") {
                        if (!same) {
                          handleswapShow();
                          setSame(true);
                        } else {
                          setSame(false);
                        }
                      }
                    }}
                  >
                    <option value={""} disabled>
                      Select Driver
                    </option>
                    {LocationByAgents?.length !== 0 ? (
                      <>
                        <option value="swapOption">
                          <div>Swap Driver</div>
                        </option>
                        {LocationByAgents?.map((e, i) => {
                          return (
                            <option key={i} value={e?._id}>
                              {e?.name ? e?.name : "N/A"}
                            </option>
                          );
                        })}
                      </>
                    ) : (
                      <option disabled>
                        <div>No Agents Available </div>
                      </option>
                    )}
                  </Form.Select>
                </Form.Group>
                <div className="col-12 mt-2">
                  <Form.Group className="mb-3 ">
                    <Form.Label>Note:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Note"
                      name="note"
                      label="note"
                      value={state.note}
                      // disabled={isContactAvailable}
                      onChange={handleInput}
                      id="note"
                      as="textarea"
                      rows={3}
                      className={classNames("mb-3", {
                        requireField: error.note,
                      })}
                    />
                  </Form.Group>
                </div>
                {error.note && (
                  <span className="text-red small d-block">{error.note}</span>
                )}
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="d-flex justify-content-center align-items-center mt-4">
                <button
                  type="button"
                  className="btn text-white bg-red"
                  onClick={() =>
                    state.location !== "" ? setIsAddItem(true) : ""
                  }
                >
                  Add&nbsp;Items
                </button>
              </div>
              <div className="text-end"></div>
              {addedItem?.length === 0 ? (
                <div className="text-center h5 mt-5 text-red">
                  {" "}
                  No items are added
                </div>
              ) : (
                <>
                  <div className="items-container mt-4">
                    <div className="cart-container">
                      {addedItem?.map((item, index) => {
                        return (
                          <div key={index} className="border p-2 rounded mb-2">
                            <div className="d-flex align-items-center justify-content-start">
                              <div className="me-3">
                                {/* <img
                                    onError={imgDefault}
                                    src={
                                      item?.image !== ""
                                        ? `${item?.image}`
                                        : "/images/logo.png"
                                    }
                                    style={{ width: "50px" }}
                                  /> */}
                              </div>
                              <div className="flex-grow-1">
                                <div className="h6">{item?.item_name}</div>
                                <div>
                                  Price: ${parseFloat(item?.price)?.toFixed(2)}
                                </div>

                                <div className="me-4">
                                  {" "}
                                  Total Price: $
                                  {parseFloat(item?.price)?.toFixed(2)} *{" "}
                                  {item.quantity} ={" "}
                                  {`$${(
                                    parseFloat(item?.price) *
                                    parseInt(item?.quantity)
                                  ).toFixed(2)}`}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex qty-controls justify-content-end">
                              <button
                                type="button"
                                className="btn btn-sm bg-red"
                                onClick={() => handleRemoveItem(item._id)}
                              >
                                -
                              </button>
                              <input
                                className="btn bg-red text-white d-flex justify-content-center align-items-centerr text-center"
                                style={{ width: "40px", height: "30px" }}
                                value={item?.quantity}
                                onChange={(e) =>
                                  handlecount(item?._id, e.target.value)
                                }
                              />
                              <div
                                className="btn btn-sm bg-red "
                                onClick={() => handleAddItem(item._id)}
                              >
                                +
                              </div>
                            </div>
                            {item?.ordernote && (
                              <div
                                className="mt-2 p-1 border border-5"
                                style={{
                                  backgroundColor: "#E9ECEF",
                                  overflowY: "auto",
                                  height: "110px",
                                  resize: "none",
                                  textAlign: "justify",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Instructions:
                                </span>{" "}
                                <span className="">{item?.ordernote}</span>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {!!freePizza && (
                      <div className="border p-2 rounded mt-3 d-flex align-items-center justify-content-start ">
                        <div className="me-3">
                          <img
                            onError={imgDefault}
                            src={
                              freePizza.image !== ""
                                ? `${freePizza.image}`
                                : "/images/logo.png"
                            }
                            style={{ width: "50px" }}
                          />
                        </div>
                        <div className="flex-grow-1">
                          <div className="h6">{freePizza.item_name}</div>
                          <div>Free Pizza</div>
                          <div>quantity: {freePizza.quantity}</div>
                        </div>
                      </div>
                    )}
                    <Form>
                      <Form.Group className="mt-4">
                        <div className="row ">
                          <div className="col-6 d-flex justify-content-between align-items-center mb-3">
                            <Form.Label className="mb-0">Sales Tax:</Form.Label>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={config.sales_tax}
                              className={"pizzaBtn"}
                              onChange={(e) => {
                                setConfig((prevState) => ({
                                  ...prevState,
                                  sales_tax: e.target.checked,
                                }));
                              }}
                            />
                          </div>
                          <div className="col-6 d-flex justify-content-between align-items-center mb-3">
                            <Form.Label className="mb-0">
                              Service Fees:
                            </Form.Label>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={config.service_fees}
                              className={"pizzaBtn"}
                              onChange={(e) =>
                                setConfig((prevState) => ({
                                  ...prevState,
                                  service_fees: e.target.checked,
                                }))
                              }
                            />
                          </div>
                          <div className="col-12 ">
                            <div className="row">
                              <div className="col-6 d-flex justify-content-between align-items-center">
                                <Form.Label className="mb-0">
                                  Delivery Fees:
                                </Form.Label>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={config.delivery_fees}
                                  className={"pizzaBtn mb-3"}
                                  onChange={(e) => {
                                    setConfig((prevState) => ({
                                      ...prevState,
                                      delivery_fees: e.target.checked,
                                    }));
                                    if (!e.target.checked) {
                                      setError((preve) => ({
                                        ...preve,
                                        delivery_fees: false,
                                      }));
                                    } else if (
                                      e.target.checked &&
                                      parseFloat(tax.delivery_fees) == 0
                                    ) {
                                      setError((preve) => ({
                                        ...preve,
                                        delivery_fees: "Enter Delivery Fees",
                                      }));
                                    }
                                  }}
                                />
                              </div>
                              {config.delivery_fees && (
                                <div className="col-6">
                                  <input
                                    type="number"
                                    name="delivery_fees"
                                    value={tax.delivery_fees}
                                    onChange={handleConfigInput}
                                    className={classNames("form-control", {
                                      requireField: error.delivery_fees,
                                    })}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          {freePizza === null ? (
                            <div className="col-12 ">
                              <div className="row">
                                <div className="col-6 d-flex justify-content-between align-items-center">
                                  <Form.Label className="mb-0">
                                    Discount:
                                  </Form.Label>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={config.manual_discount}
                                    className={"pizzaBtn mb-3"}
                                    onChange={(e) => {
                                      setConfig((prevState) => ({
                                        ...prevState,
                                        manual_discount: e.target.checked,
                                      }));
                                      if (!e.target.checked) {
                                        setError((preve) => ({
                                          ...preve,
                                          manual_discount: false,
                                        }));
                                      } else if (
                                        e.target.checked &&
                                        parseFloat(tax.manual_discount) == 0
                                      ) {
                                        setError((preve) => ({
                                          ...preve,
                                          manual_discount:
                                            "Enter Delivery Fees",
                                        }));
                                      }
                                    }}
                                  />
                                </div>
                                {config.manual_discount && (
                                  <div className="col-6">
                                    <input
                                      type="number"
                                      name="manual_discount"
                                      value={tax.manual_discount}
                                      onChange={handleConfigInput}
                                      className={classNames("form-control", {
                                        requireField: error.manual_discount,
                                      })}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div className="col-6 d-flex justify-content-between align-items-center">
                            <Form.Label className="mb-0">
                              Tip Amount:
                            </Form.Label>
                            <div className="">
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={config.tip}
                                className={"pizzaBtn mb-3"}
                                onChange={(e) => {
                                  setConfig((prevState) => ({
                                    ...prevState,
                                    tip: e.target.checked,
                                  }));
                                  if (!e.target.checked) {
                                    setError((preve) => ({
                                      ...preve,
                                      total_tipamount: false,
                                    }));
                                  } else if (
                                    e.target.checked &&
                                    parseFloat(tax.total_tipamount) == 0
                                  ) {
                                    setError((preve) => ({
                                      ...preve,
                                      total_tipamount: "Enter Delivery Fees",
                                    }));
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {config.tip && (
                            <div className="col-6">
                              <input
                                name="total_tipamount"
                                value={tax.total_tipamount}
                                onChange={handleConfigInput}
                                className={classNames("form-control", {
                                  requireField: error.total_tipamount,
                                })}
                              />
                            </div>
                          )}
                          {config.tip && (
                            <>
                              {addInput?.map((v, i) => {
                                return (
                                  <div className="row">
                                    <div className="col-5">
                                      <div className="">
                                        <Form.Group className="mb-3">
                                          <Form.Label
                                            style={{
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Name:
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Name"
                                            name="name"
                                            onChange={(e) => {
                                              handleConfigInput(e, i);
                                            }}
                                            className={classNames({
                                              requireField:
                                                addInputErrors[i].name === "",
                                            })}
                                            value={v.name}
                                            label="name"
                                            id="name"
                                          />
                                        </Form.Group>
                                      </div>
                                    </div>
                                    <div className="col-5">
                                      {" "}
                                      <div className="">
                                        <Form.Group className="mb-3">
                                          <Form.Label
                                            style={{
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Amount:
                                          </Form.Label>
                                          <Form.Control
                                            type="number"
                                            name="amount"
                                            label="amount"
                                            maxLength={6}
                                            onChange={(e) =>
                                              handleConfigInput(e, i)
                                            }
                                            className={classNames({
                                              requireField:
                                                addInputErrors[i].amount === "",
                                            })}
                                            value={v.amount}
                                            placeholder="Amount"
                                            id="amount"
                                          />
                                        </Form.Group>
                                      </div>
                                    </div>
                                    <div className="col-1">
                                      <button
                                        className="btn bg-dark text-white mt-4 px-3"
                                        type="button"
                                        onClick={(e) => {
                                          removeInputeValue(i, v.amount);
                                          removeInputeValueErrors(i);
                                        }}
                                      >
                                        <i className="fa fa-minus"></i>
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                              {parseFloat(tax.total_tipamount) <
                              parseFloat(ChangeAmount) ? (
                                <>
                                  <span className="text-red text-center ">
                                    The tip distribution cannot be more than the
                                    total tip amount.
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="col col-4">
                                <button
                                  className="btn bg-red py-1 text-white add-more_Address"
                                  type="button"
                                  onClick={addInputValue}
                                >
                                  <i className="fa-solid fa-plus"></i> Add
                                </button>
                              </div>
                            </>
                          )}

                          {/* {config.tip && (
                             <div className="col-12 mt-4">
                               <Form.Label>PRM Amount:</Form.Label>
                               <Form.Control
                                 name="prm_amount"
                                 type="text"
                                 placeholder="Enter PRM Amount"
                                 value={tax.prm_amount}
                                 onChange={handleConfigInput}
                                 className={classNames({
                                   requireField: error.prm_amount,
                                 })}
                               />
                             </div>
                           )} */}
                        </div>
                      </Form.Group>
                    </Form>

                    <div className="mt-3 ms-auto border-top">
                      <div className="row mt-2 justify-content-end">
                        <div className="col-6 bold">Total Amount: </div>
                        <div className="col-6">
                          ${parseFloat(totalAmount.total)?.toFixed(2)}
                        </div>
                      </div>
                      {freePizza !== null ? (
                        <div className="row mt-2 justify-content-end ">
                          <div className="col-6 bold">Discount: </div>
                          <div className="col-6">
                            $
                            {freePizza !== null
                              ? parseFloat(freePizza?.price)?.toFixed(2)
                              : parseFloat(0)?.toFixed(2)}
                          </div>
                        </div>
                      ) : (
                        config?.manual_discount && (
                          <div className="row mt-2 justify-content-end ">
                            <div className="col-6 bold">Manual Discount: </div>
                            <div className="col-6">
                              $
                              {tax.manual_discount
                                ? parseFloat(tax?.manual_discount)?.toFixed(2)
                                : parseFloat(0)?.toFixed(2)}
                            </div>
                          </div>
                        )
                      )}
                      {config.sales_tax && (
                        <div className="row mt-2 justify-content-end">
                          <div className="col-6 bold">Sales Tax: </div>
                          <div className="col-6">
                            $(
                            {(
                              ((totalAmount?.total - tax.manual_discount) *
                                locationSalestax) /
                              100
                            ).toFixed(2)}{" "}
                            ) ({parseFloat(locationSalestax)?.toFixed(2)} %)
                          </div>
                        </div>
                      )}
                      <div className="row mt-2 justify-content-end">
                        <div className="col-6 bold">Sub Total: </div>
                        <div className="col-6">
                          $
                          {/* {freePizza !== null
                         ? (
                             parseFloat(totalAmount.subTotal) -
                             parseFloat(freePizza?.price)
                           ).toFixed(2)
                         : (
                             parseFloat(totalAmount.total) -
                             parseFloat(
                               !!config.manual_discount
                                 ? tax.manual_discount
                                 : 0
                             ) +
                             parseFloat(!!config.sales_tax ? tax.sales_tax : 0)
                           ).toFixed(2)} */}
                          {/* {totalAmount?.subTotal?.toFixed(2)} */}
                          {freePizza !== null
                            ? (
                                totalAmount?.subTotal - freePizza?.price
                              ).toFixed(2)
                            : (totalAmount?.subTotal).toFixed(2)}
                        </div>
                      </div>
                      {config.service_fees && (
                        <div className="row mt-2 justify-content-end">
                          <div className="col-6 bold">Service Fees: </div>
                          <div className="col-6">
                            {`$${(
                              (parseFloat(totalAmount.subTotal) *
                                parseFloat(locationservicefee)) /
                              100
                            ).toFixed(2)} (${locationservicefee} %)`}
                          </div>
                        </div>
                      )}
                      {config.delivery_fees && (
                        <div className="row mt-2 justify-content-end">
                          <div className="col-6 bold">Delivery Fees: </div>
                          <div className="col-6">
                            {tax.delivery_fees === ""
                              ? parseFloat(0)?.toFixed(2)
                              : "$" + parseFloat(tax.delivery_fees)?.toFixed(2)}
                          </div>
                        </div>
                      )}
                      {config.tip && (
                        <div className="row mt-2 justify-content-end">
                          <div className="col-6 bold">Tip Amount: </div>
                          <div className="col-6">
                            {tax.total_tipamount === ""
                              ? parseFloat(0)?.toFixed(2)
                              : "$" +
                                parseFloat(tax.total_tipamount)?.toFixed(2)}
                          </div>
                        </div>
                      )}

                      <div className="row mt-2 justify-content-end">
                        <div className="col-6 bold">Grand Total: </div>
                        <div className="col-6">
                          {/* $ {totalAmount?.grandTotal?.toFixed(2)} */}$
                          {freePizza !== null
                            ? (
                                parseFloat(totalAmount?.grandTotal) -
                                parseFloat(freePizza?.price)
                              ).toFixed(2)
                            : parseFloat(totalAmount?.grandTotal).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>

          {/* <Toaster /> */}
        </div>

        <Modal
          show={isAddItem}
          onHide={() => setIsAddItem(false)}
          size="lg"
          backdrop="static"
          dialogClassName="modal-dialog-scrollable"
        >
          <Additem
            setIsAddItem={setIsAddItem}
            setAddedItem={setAddedItem}
            addedItem={addedItem}
            setChepestPizzaItem={setChepestPizzaItem}
            locationID={state.location}
          />
        </Modal>
      </Modal.Body>
      <Modal.Footer className="mt-3 ">
        <div className="d-flex justify-content-end">
          {/* <div
              className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div> */}
          <div
            className="border rounded pointer px-4 py-2 text-white bg-red"
            onClick={!btnLoading ? handleCreateOrder : () => {}}
          >
            {btnLoading ? <Loader /> : "Update Order"}
          </div>
        </div>
      </Modal.Footer>

      <Modal
        show={swapShow}
        onHide={() => {
          handleswapClose();
          setState((pre) => ({
            ...pre,
            delivery_agent: "",
          }));
        }}
        backdrop="static"
        keyboard={false}
        id="myModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row mx-0">
              <div className="col-10">
                <b>Agent Name</b>
              </div>
              <div className="col-2"></div>
            </div>
            <hr style={{ borderTop: "1px solid black" }} className="my-2" />
            {swapAgents.length !== 0 ? (
              <>
                {swapAgents.map((e, i) => {
                  return (
                    <div className="row mx-0 border-bottom py-2" key={i}>
                      <div className="col-10">
                        <b>
                          {e.order_name} ({e.agent_id.name})
                        </b>
                      </div>
                      <div className="col-2">
                        <input
                          type="checkbox"
                          name=""
                          value={e._id}
                          checked={swapedId == e._id}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setSwapedId(e._id);
                            } else {
                              setSwapedId("");
                            }
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>No Agents Available</>
            )}
          </div>
          <div className="d-flex justify-content-end mt-4">
            <div
              className="border rounded pointer px-4 py-2 text-white bg-red"
              onClick={() => {
                if (swapedId != "") {
                  setState((prevState) => ({
                    ...prevState,
                    delivery_agent: "swapOption",
                  }));
                } else {
                  if (state.delivery_agent == "swapOption") {
                    setState((prevState) => ({
                      ...prevState,
                      delivery_agent: currentAgent?._id,
                    }));
                  }
                }
                handleswapClose();
              }}
            >
              Swap Driver
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProp = (state) => ({
  SuperAdminOrderReducer: state.SuperAdminOrderReducer,
  orderfilterSuperadmin: state.SuperAdminOrderReducer.orderfilterSuperadmin,
  GetorderReducer: state.SuperAdminOrderReducer.getOrder,
  getLocationByAgents: state.getLocationByAgents.getLocationByAgents,
  editOrderReducer: state.SuperAdminOrderReducer.updateOrder,
  getLocationByMenuReducer: state.getLocationByMenu.getLocationByMenu,
  getswapagentReducer: state.getswapagentReducer.getswapagent,
  searchLocationReducer: state.searchLocationReducer.getLocationData,
});

const mapDispatchToProps = (dispatch) => ({
  getOrder: (Details) => dispatch(getOrderDataAction(Details)),
  getFreeAgentData: (details) => dispatch(getFreeAgentData(details)),
  resetOrder: () => dispatch({ type: RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR }),
  resetUpdateOrder: () => dispatch({ type: RESET_UPDATE_SUPER_ADMIN_ORDER }),
  getLocationByMenuDataAction: (id) =>
    dispatch(getLocationByMenuDataAction(id)),
  locationSearch: (details) => dispatch(locationSearch(details)),
  UpdateOrderAction: (details) => dispatch(UpdateOrderAction(details)),
  getOrderFilterAction: (details) => dispatch(getOrderFilterAction(details)),
  getswapagantAction: (details) => dispatch(getswapagantAction(details)),
});

export default connect(mapStateToProp, mapDispatchToProps)(EditOrder);
